import jQuery from "./vendor/jquery.min";
import Dropzone from "./vendor/dropzone.min";
import { MDCMenu } from "@material/menu";
import { MDCSnackbar } from "@material/snackbar";
import { MDCDialog } from "@material/dialog";
import { MDCTabBar } from "@material/tab-bar";

(function ($) {
  //Load url, ajax
  $("body").on("click", ".js-load-ajax", function () {
    loadUrlAjax($(this));
    return false;
  });

  function loadUrlAjax(element) {
    let url = element.data("url");
    let target = element.data("target") ? element.data("target") : "";

    if (url) {
      jQuery
        .ajax({
          url: url,
        })
        .done(function (data) {
          if (target) {
            jQuery(target).html(data);
          } else {
            location.reload();
          }
        })
        .fail(function () {
          console.log("error loading url: " + url);
        });
    }
  }

  //Submit a form
  $("body").on("click", ".js-submit-form", function () {
    submitForm($(this));
  });
  function submitForm(element) {
    let formid = element.data("formid");
    $(element).prop("disabled", true);
    jQuery("#" + formid).submit();
    return false;
  }
  //

  const menuButton = document.querySelector("#show-items");
  if (menuButton) {
    menuButton.addEventListener("click", () => {
      const menu = new MDCMenu(document.querySelector(".mdc-menu"));
      menu.open = true;
    });
  }

  const snackbarButton = document.querySelector(".show-snackbar");
  if (snackbarButton) {
    snackbarButton.addEventListener("click", () => {
      const snackbar = new MDCSnackbar(document.querySelector(".mdc-snackbar"));
      snackbar.open = true;
    });
  }

  const dialogButton = document.querySelector(".show-dialog");
  if (dialogButton) {
    dialogButton.addEventListener("click", () => {
      const dialog = new MDCDialog(document.querySelector(".mdc-dialog"));
      dialog.open();
    });
  }

  $("body").on("click", ".close-dialog", function (e) {
    const dialog = document.querySelector(".mdc-dialog");
    dialog.classList.remove(
      "mdc-dialog--open",
      "mdc-dialog--alert",
      "mdc-dialog--small-right",
      "mdc-dialog--small",
      "mdc-dialog--message"
    );

    // activate documents view and reload the page
    if ($(this).hasClass("reload-documents-view")) {
      window.location = "#documents-view";
      location.reload();
    }
  });

  const rightPanel = document.querySelector(".right-panel");

  $("body").on("click", "#closeRightPanel", function (e) {
    if ($("#account-settings_fields").length > 0) {
      $("#account-settings_fields button").nextAll().remove();
    }
    rightPanel.classList.remove("open", "hide-header");
  });

  const tabBarEl = document.querySelector(".mdc-tab-bar");
  if (tabBarEl) {
    const tabBar = new MDCTabBar(tabBarEl);
    let contentEls = document.querySelectorAll(".tab-content");

    tabBar.listen("MDCTabBar:activated", function (event) {
      // Hide currently-active content
      document
        .querySelector(".tab-content--active")
        .classList.remove("tab-content--active");
      // Show content for newly-activated tab
      contentEls[event.detail.index].classList.add("tab-content--active");
    });
  }

  function openDropDownMenu() {
    document
      .querySelectorAll(".show-dropdown-menu")
      .forEach(function (element) {
        element.addEventListener("click", function () {
          const dataId = element.dataset.id;
          const usingDt = document.querySelector(`#${dataId}`);
          const menu = new MDCMenu(usingDt);
          menu.open = true;

          menu.listen("MDCMenu:selected", (item) => {
            if (!item.detail.item.classList.contains("view-group-field")) {
              const itemId = item.detail.item.getAttribute("data-id");
              const dialogItem = document.querySelector(`#${itemId}`);
              if (dialogItem) {
                const dialog = new MDCDialog(dialogItem);
                dialog.open();
              }
            }
          });
        });
      });
  }

  openDropDownMenu();

  $("body").on("click", ".js-show-confirmation-dialog", function () {
    showConfirmationDialog($(this));
  });

  function showConfirmationDialog(element) {
    let url = element.data("url");
    if (url) {
      jQuery
        .ajax({
          url: url,
        })
        .done(function (data) {
          jQuery("#add-popup-content").html(data);
          jQuery("#add-popup").addClass(
            "mdc-dialog--open mdc-dialog--alert mdc-dialog--small"
          );
        })
        .fail(function () {
          console.log("error");
        });
    }
  }

  $("body").on("click", ".js-show-create-dialog", function () {
    showCreateDialog($(this));
  });

  function showCreateDialog(element) {
    let url = element.data("url");
    let size = element.data("size") ? element.data("size") : "";
    let position = element.data("position") ? element.data("position") : "";
    let type = element.data("type") ? element.data("type") : "";
    let dialogClass;
    if (size && position) {
      dialogClass = "mdc-dialog--small";
    } else if (size) {
      dialogClass = "mdc-dialog--small-right";
    }
    if (type === "message") {
      dialogClass += " mdc-dialog--message";
    }

    if (url) {
      $("body").addClass("loading");
      $.ajax({
        url: url,
      })
        .done(function (data) {
          $("#add-popup-content").html(data);
          $("#add-popup").addClass(`mdc-dialog--open ${dialogClass}`);
          $("body").removeClass("loading");
        })
        .fail(function () {
          console.log("error");
          $("body").removeClass("loading");
        });
    }
  }

  /**** Intergration information *****/
  $("body").on("click", ".group-clockify-integration", function () {
    showIntergrationDialog();
  });

  $("body").on("click", ".close-intergration-dialog", function () {
    let dialogClass = "mdc-dialog--small-center";
    $("#intergration-popup").removeClass(`mdc-dialog--open ${dialogClass}`);
  });

  function showIntergrationDialog() {
    let dialogClass = "mdc-dialog--small-center";
    $("#intergration-popup").addClass(`mdc-dialog--open ${dialogClass}`);
  }

  /***** *****/

  /**** No activity allocated to time *****/

  $("body").on("click", ".close-warning-dialog", function () {
    $("#no-activity-warning").removeClass(`mdc-dialog--open`);
  });

  function showWraningDialog() {
    $("#no-activity-warning").addClass(`mdc-dialog--open`);
  }

  $(".js-show-warning-dialog").on("click", function () {
    showWraningDialog();
  });

  /***** *****/
  /**** Help text *****/
  $("body").on("click", ".help-text-icon", function () {
    let id = $(this).attr("data-id");
    showHelpTextDialog(id);
  });

  function showHelpTextDialog(id) {
    $("#add-popup").addClass(`mdc-dialog--open`);
    let helpText = $(`#${id}`).clone();
    jQuery("#add-popup .mdc-dialog__inner").html(helpText);
  }

  /***** *****/

  $(".js-logout-btn").click(function () {
    showLogoutForm();
  });

  function showLogoutForm() {
    var html = $("#logout-confirmation").clone();
    $("#add-popup-content").html(html);
    $("#add-popup").addClass(
      "mdc-dialog--open mdc-dialog--alert mdc-dialog--small"
    );
  }

  $("body").on("click", ".create-new-btn", function (e) {
    e.preventDefault(); // avoid to execute the actual submit of the form.
    let form = $(this).closest("form");
    let url = form.attr("action");
    let ajaxRefresh = $(this).data("target") ? $(this).data("target") : "";

    $(this).addClass("button--loading");
    $(this).attr("disabled", true);

    $.ajax({
      type: "POST",
      url: url,
      data: form.serialize(), // serializes the form's elements.
    })
      .done(function (data) {
        if (ajaxRefresh) {
          $("#add-popup-content").html("");
          $("#add-popup").removeClass(`mdc-dialog--open`);
          jQuery(ajaxRefresh).html(data);
        } else {
          location.reload();
          $(this).removeClass("button--loading");
          $(this).attr("disabled", false);
        }
      })
      .fail(function (data) {
        $("#add-popup-content").html(data.responseText);
        $(this).removeClass("button--loading");
        $(this).attr("disabled", false);
      });
  });

  $("body").on("click", ".update-settings-btn", function (e) {
    e.preventDefault(); // avoid to execute the actual submit of the form.
    let form = $(this).closest("form");
    let url = form.attr("action");
    $(this).addClass("button--loading");
    $(this).attr("disabled", true);

    $.ajax({
      type: "POST",
      url: url,
      data: form.serialize(), // serializes the form's elements.
    })
      .done(function (data) {
        location.reload();
        $(this).removeClass("button--loading");
        $(this).attr("disabled", false);
      })
      .fail(function (data) {
        $("#account-settings_fields button").after(data.responseText);
        $(this).removeClass("button--loading");
        $(this).attr("disabled", false);
      });
  });

  $("body").on("click", ".edit-information-btn", function (e) {
    e.preventDefault(); // avoid to execute the actual submit of the form.
    let form = $(this).closest("form");
    let url = form.attr("action");
    $(this).addClass("button--loading");
    $(this).attr("disabled", true);
    $.ajax({
      type: "POST",
      url: url,
      data: form.serialize(), // serializes the form's elements.
    })
      .done(function (data) {
        location.reload();
        $(this).removeClass("button--loading");
        $(this).attr("disabled", false);
      })
      .fail(function (data) {
        $(".right-panel").html(data.responseText);
        $(this).removeClass("button--loading");
        $(this).attr("disabled", false);
      });
  });

  $("body").on("click", ".js-show-upload-dialog", function () {
    showUploadForm($(this));
  });

  function showUploadForm(element) {
    let url = element.data("url");
    $.ajax({
      url: url,
    }).done(function (data) {
      $("#add-popup-content").html(data);
      $("#add-popup").addClass("mdc-dialog--open");
      initFileUploader("#zdrop");
    });
  }

  function initFileUploader(target) {
    let previewNode = document.querySelector("#zdrop-template");
    previewNode.id = "";
    let previewTemplate = previewNode.parentNode.innerHTML;
    previewNode.parentNode.removeChild(previewNode);
    let zdrop = new Dropzone(target, {
      previewTemplate: previewTemplate,
      previewsContainer: "#previews",
      clickable: ".upload-btn",
      maxFilesize: 20,
    });

    zdrop.on("addedfile", function () {
      $(".preview-container").css("visibility", "visible");
    });

    zdrop.on("uploadprogress", function (file, progress) {
      if (file.previewElement) {
        let progr = file.previewElement.querySelector(".progress .determinate");
        if (progr === undefined || progr === null) return;
        progr.style.width = progress + "%";
      }
    });

    zdrop.on("dragenter", function () {
      $(".fileuploader").addClass("active");
    });

    zdrop.on("dragleave", function () {
      $(".fileuploader").removeClass("active");
    });

    zdrop.on("drop", function () {
      $(".fileuploader").removeClass("active");
    });

    zdrop.on("success", function (file, json) {
      $("#zdrop")
        .closest(".mdc-dialog")
        .find(".close-dialog")
        .addClass("reload-documents-view");
    });
  }

  /*** Main content switching tab ***/
  let contentPanelNav = $(".main-content .menu__link");
  let anchor = window.location.hash;
  if (anchor) {
    switchTab(window.location.hash.substring(1));
  }

  function switchTab(element) {
    let menuItem = $(".menu").find(`[data-id='${element}']`);
    if (menuItem.length > 0) {
      $(".content-panel-view").hide();
      $("#" + element).toggle();
      contentPanelNav.removeClass("menu__link--current");
      menuItem.addClass("menu__link--current");
    }
  }

  contentPanelNav.click(function () {
    let id = $(this).attr("data-id");
    switchTab(id);
  });

  /*** Field group select open right hand panel form ***/
  $("body").on("click", ".view-group-field", function () {
    const fieldGroupWrapper = $(".view-group-field-wrapper");
    if ($("#account-settings_fields").length > 0) {
      $("#account-settings_fields button").nextAll().remove();
    }
    let id = $(this).attr("data-id");
    fieldGroupWrapper.hide();
    $(".right-panel").addClass("open");
    $(".right-panel").addClass("open full-width no-padding");
    $(".right-panel").removeClass("hide-header");
    $("#" + id).toggle();
    $(".right-panel .view-group-field").removeClass("menu__link--current");
    $("." + id + "_nav").addClass("menu__link--current");
  });

  /*** Dropdown select open right hand panel popup form ***/
  let rightPopupBtn = $(".view-right-popup-field");

  /*** Field group select open right hand panel form(example integration) ***/
  rightPopupBtn.click(function () {
    let url = $(this).attr("data-url");

    $("body").addClass("loading");
    if (url) {
      $.ajax({
        url: url,
      })
        .done(function (data) {
          $(".right-panel").addClass("open hide-header full-width");
          $("#account-settings_fields button").nextAll().remove();
          $("#account-settings_fields button").after(data);
          $("body").removeClass("loading");
        })
        .fail(function () {
          $("body").removeClass("loading");
          console.log("error");
        });
    }
  });

  /*** adjust the height of left panel inner based on breadcrumbs height ***/
  function setSidebarHeight() {
    if ($(".breadcrumb").length > 0) {
      let breadcrumbsHeight = $(".breadcrumb").height();
      let leftPanelHeight = $(".left-panel").height();
      let height = leftPanelHeight - breadcrumbsHeight;
      $(".left-panel__inner").css("min-height", `${height}px`);
    }
  }

  setSidebarHeight();

  $("body").on("change", "#id_project", function () {
    let id = $(this).find(":selected")[0].value;

    $.ajax({
      type: "Get",
      url: `/api/v1/projects/activities/?project=${id}`,
      data: {
        id: id,
      },
      success: function (data) {
        let $activity = $("#id_activity");
        $activity.empty();
        if (data.length > 0) {
          $activity.attr("disabled", false);

          for (let i = 0; i < data.length; i++) {
            $activity.append(
              `<option value="${data[i].id}">${data[i].name}</option>`
            );
          }
        } else {
          $activity.attr("disabled", true);
          $activity.prev(".floating-label").removeClass("active");
        }
      },
    });
  });

  $("body").on("change", "#id_project, #id_activity", function () {
    let form = $(this).closest("form.update-time");
    if (form.length > 0) {
      let wrapper = $(this).closest("form").find(".mdc-dialog__actions");
      if (wrapper.find($(".errorlist")).length === 0) {
        $(
          wrapper.prepend(
            '<ul class="errorlist"><li>These changes will move the time log to another project / activity. Do you want to proceed?</li></ul>'
          )
        );
      }
    }
  });

  //redirect to login page when session expired
  $(document).ajaxError(function (event, jqxhr, settings, thrownError) {
    if (jqxhr.status == 401 || jqxhr.status == 500) {
      window.location.reload();
    }
  });

  $(".tooltip").on("mouseover", function (e) {
    var tooltip = $(this).find(".tooltip__text"),
      ww = $(window).outerWidth(), //window width
      tl = tooltip.offset().left, //Tooltip left pos
      tw = tooltip.outerWidth(); //Tooltip Width
    if (tl + tw > ww) {
      tooltip.css({ left: "auto", right: 0, transform: "translateX(0)" });
    } else if (tl < 0) {
      tooltip.css({ left: "0", transform: "translateX(0)" });
    }
  });

  // save the id of opened accordion in local storge so we can open it after page get refreshed
  $(".js-save-accordion").on("click", function () {
    let item = $(this)
      .closest(".accordion-item")
      .find(".accordion-item__title-container");
    let itemId = item.attr("id");
    localStorage.setItem("accordion", itemId);
  });

  // On page reload if there is id of an accordion in the local storge open it
  $(window).on("load", function () {
    if (localStorage.getItem("accordion")) {
      let item = localStorage.getItem("accordion");
      let itemId = $(`#${item} .accordion-item__arrow`);
      if (itemId.length > 0) {
        $(`#${item} .accordion-item__arrow`).click();
      }
      localStorage.removeItem("accordion");
    }
  });

  // redirect to clientcompany documents
  window.onload = function () {
    var viewDocument = document.getElementById("documents-view");
    if (window.location.href.indexOf("documents-view") > -1) {
      document.getElementById("view-documents").click();
      var url = location.protocol + "//" + location.host + location.pathname;
      window.history.replaceState({}, null, url);
    }
  };

  // display clientcompany edit form
  $(document).ready(function () {
    $("#edit_clientcompany-info").click(function () {
      var nav = document.getElementsByClassName("company_fields_nav");
      for (var i = 0; i < nav.length; i++) {
        nav[i].style.display = "";
      }
      document.getElementById("documents-view").style.display = "none";
      document.getElementById("document_menu-link").style.display = "none";
    });
  });

  // display clientcompany document list
  document.getElementById("view-documents").addEventListener(
    "click",
    function () {
      document.getElementById("company_fields").style.display = "none";
      var nav = document.getElementsByClassName("company_fields_nav");
      for (var i = 0; i < nav.length; i++) {
        nav[i].style.display = "none";
      }
      document.getElementById("document_menu-link").style.display = "";
      document.getElementById("documents-view").style.display = "";
    },
    false
  );

  let site = (window.site = {});
  site.form = {
    active: function (element) {
      element.siblings("label").addClass("active");
      element.addClass("active");
      element.parent().addClass("active");
    },

    inactive: function (element) {
      element.siblings("label").removeClass("active");
      element.removeClass("active");
      element.parent().removeClass("active");
    },

    init: function () {
      let that = this;
      let inputSelector =
        "input[type=text], input[type=password], input[type=email], input[type=date], input[type=url], input[type=tel], input[type=number], input[type=search], textarea, select";
      let select = "select";

      $(inputSelector).each(function (index, element) {
        if ($(element).val().length > 0 || element.autofocus) {
          that.active($(element));
        }
      });

      $(document).on("blur", "input, textarea", function () {
        if ($(this).val().length > 0) {
          that.active($(this));
        } else {
          that.inactive($(this));
        }
      });

      $(document).on("focus", "input, textarea", function () {
        that.active($(this));
      });

      $(document).on("DOMNodeInserted", function () {
        $(select).each(function (index, element) {
          let option = $(element).val();
          if (option !== "") {
            $(this).prev().addClass("active");
          } else {
            $(this).prev().removeClass("active");
          }
        });
      });

      $(document).on("change", "select", function () {
        let option = $(this).val();
        if (option !== "") {
          $(this).prev().addClass("active");
        } else {
          $(this).prev().removeClass("active");
        }
      });
    },
  };

  site.form.init();
})(jQuery);
